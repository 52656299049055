import { designSystemColors } from "../../../styles/colors";
import { CheckboxSize, CheckboxState } from "./Checkbox.type";

export const CHECKBOX_SIZE_STYLE_MAP: {
  [key in CheckboxSize]: string;
} = {
  big: "h-[24px] w-[24px] p-[3px]",
  small: "h-[20px] w-[20px] p-[2.5px]",
} as const;

type DesignSystemColorsValues =
  (typeof designSystemColors)[keyof typeof designSystemColors];

export const CHECKBOX_COLOR_STYLE_MAP: {
  [state in CheckboxState]: DesignSystemColorsValues;
} = {
  checked: designSystemColors["background-brand-default"],
  unchecked: designSystemColors["icon-subtler"],
  indeterminate: designSystemColors["background-brand-default"],
  disabled: designSystemColors["icon-disabled"],
} as const;
