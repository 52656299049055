"use client";

import { twMerge } from "tailwind-merge";
import { forwardRef } from "react";
import { customTwMerge } from "../../../utils/customTwMerge";
import IconCheckboxIndeterminate from "../../atoms/Icon/svgs/components/IconCheckboxIndeterminate";
import { Label } from "../Label/Label";
import IconCheckboxChecked from "../../atoms/Icon/svgs/components/IconCheckboxChecked";
import IconCheckboxUnchecked from "../../atoms/Icon/svgs/components/IconCheckboxUnchecked";
import { CheckboxProps, CheckboxState } from "./Checkbox.type";
import {
  CHECKBOX_COLOR_STYLE_MAP,
  CHECKBOX_SIZE_STYLE_MAP,
} from "./Checkbox.style";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      id,
      size = "small",
      indeterminate = false,
      checked = false,
      disabled = false,
      labelText,
      className,
      onChange,
      ...restProps
    }: CheckboxProps,
    ref,
  ) {
    const state: CheckboxState = disabled
      ? "disabled"
      : indeterminate
      ? "indeterminate"
      : checked
      ? "checked"
      : "unchecked";

    const cursorStyle = !disabled ? "cursor-pointer" : "cursor-not-allowed";
    const hoverStyle =
      state === "unchecked"
        ? "[&>svg]:hover:fill-icon-subtle"
        : state === "checked" || state === "indeterminate"
        ? "[&>svg]:hover:fill-background-brand-hover"
        : "";

    const iconFill = CHECKBOX_COLOR_STYLE_MAP[state];
    const iconClassName = CHECKBOX_SIZE_STYLE_MAP[size];

    return (
      <span
        className={customTwMerge(
          "inline-flex items-center justify-center gap-[6px]",
          cursorStyle,
          className,
        )}
      >
        <span
          className={twMerge(
            "relative inline-flex items-center justify-center",
            hoverStyle,
          )}
        >
          {indeterminate && (
            <IconCheckboxIndeterminate
              className={iconClassName}
              fill={iconFill}
            />
          )}
          {!indeterminate && checked && (
            <IconCheckboxChecked className={iconClassName} fill={iconFill} />
          )}
          {!indeterminate && !checked && (
            <IconCheckboxUnchecked className={iconClassName} fill={iconFill} />
          )}

          <input
            id={id}
            ref={ref}
            className={twMerge(
              "absolute left-0 top-0 opacity-0",
              cursorStyle,
              CHECKBOX_SIZE_STYLE_MAP[size],
            )}
            type="checkbox"
            checked={checked}
            onChange={disabled ? undefined : onChange}
            {...restProps}
          />
        </span>

        {!!labelText && (
          <Label
            as="label"
            size={size}
            htmlFor={id}
            className={twMerge(cursorStyle, disabled && "text-text-disabled")}
          >
            {labelText}
          </Label>
        )}
      </span>
    );
  },
);
