"use client";

import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAlert } from "@/components/organisms/Alert/useAlert";
import {
  CustomMessageEvent,
  CustomMessageType,
} from "@/types/messageEvent.type";
import { usePostV1UserLoginGoogleCallback } from "@/api/client/user/user";
import { PAGE_ROUTES } from "@/constants/pageRoute.constant";
import {
  StatusCode,
  STATUS_CODE_ALERT_MESSAGE_MAP,
} from "@/api/statusCode.constant";

export function useListenGoogleSignInCallback() {
  const { showAlert } = useAlert({
    title: "간편 회원가입으로 가입된\n이메일입니다.",
    titleClassName: "px-[24px]",
  });

  const searchParams = useSearchParams();

  const { mutate: googleSignInCallback } = usePostV1UserLoginGoogleCallback({
    mutation: {
      onSuccess: (data) => {
        const statusCode = data.data.status?.code;
        if (statusCode === StatusCode.Success) {
          if (data.data.data?.result === "need_agreement") {
            router.push(PAGE_ROUTES.googleSignUp);
          } else {
            router.push(
              searchParams.get("returnUrl") ||
                PAGE_ROUTES.dashboard.re100Management,
            );
          }
        } else {
          showAlert({ props: STATUS_CODE_ALERT_MESSAGE_MAP[statusCode] });
        }
      },
    },
  });

  const router = useRouter();
  const debouncedGoogleSignInCallback = useDebouncedCallback(
    googleSignInCallback,
    100,
  );

  useEffect(() => {
    const handlePopupMessage = ({ data }: CustomMessageEvent) => {
      if (data.id === CustomMessageType.GoogleSignIn) {
        const { state, code } = data.data;
        debouncedGoogleSignInCallback({ data: { state, code } });
      }
    };
    window.addEventListener("message", handlePopupMessage);
    return () => {
      window.removeEventListener("message", handlePopupMessage);
    };
  }, [debouncedGoogleSignInCallback]);
}
