"use client";

import { usePostV1UserLoginGoogle } from "@/api/client/user/user";
import { StatusCode } from "@/api/statusCode.constant";

export function useGoogleSignIn() {
  const { mutateAsync: googleSignIn } = usePostV1UserLoginGoogle({
    mutation: {
      onSuccess: (result) => {
        if (result.data.status?.code === StatusCode.Success) {
          const left = window.screen.width / 2 - GOOGLE_SIGN_IN_POPUP_WIDTH / 2;
          const top =
            window.screen.height / 2 - GOOGLE_SIGN_IN_POPUP_HEIGHT / 2;
          window.open(
            result.data.data.url,
            "GoogleOauth",
            `width=${GOOGLE_SIGN_IN_POPUP_WIDTH},height=${GOOGLE_SIGN_IN_POPUP_HEIGHT},left=${left},top=${top}`,
          );
        }
      },
    },
  });

  return googleSignIn;
}

const GOOGLE_SIGN_IN_POPUP_WIDTH = 500;
const GOOGLE_SIGN_IN_POPUP_HEIGHT = 600;
