export enum CustomMessageType {
  GoogleSignIn,
}

export type CustomMessageData = {
  id: CustomMessageType.GoogleSignIn;
  data: {
    code: string;
    state: string;
  };
};

export type CustomMessageEvent = MessageEvent<CustomMessageData>;
